import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [storySad, setStorySad] = useState('');
  const [story, setStory] = useState('');

  useEffect(() => {
    fetch('story.json')
      .then(response => response.json())
      .then(data => {
        setStory(data.storycontent);
        setStorySad(data.storysad);
      });
  }, []);

  return (
    <div className="App">
      <h1 className="App-header">Happy Mother's Day</h1>
      <div className="App-video">
        <video width="320" height="240" controls loop autoPlay muted>
          <source src="carol.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <h3 className="App-header">HEADS</h3>
      <div className="App-story-sad">
      {storySad.split('<br />').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))}
      </div>
      <h3 className="App-header">TAILS</h3>
      <div className="App-story-happy">
        {story.split('<br />').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))}
      </div>
    </div>
  );
}

export default App;